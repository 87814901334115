module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/runner/work/bodycoach-web/bodycoach-web/src/components/layout/Layout.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Body Coach","short_name":"Body Coach","start_url":"/","background_color":"#0053C7","theme_color":"#ffffff","icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2d50b4f88145c27354e2d572fc51f576"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"consentOnContinuedBrowsing":false,"whitelabel":false,"lang":"en","siteId":"2020675","googleAdditionalConsentMode":true,"isTCFConsentGlobal":false,"cookiePolicyId":"13783202","cookiePolicyUrl":"https://www.iubenda.com/privacy-policy/83525549","banner":{"acceptButtonDisplay":true,"rejectButtonDisplay":true,"customizeButtonDisplay":true,"acceptButtonColor":"#ffffff","acceptButtonCaptionColor":"#18335b","customizeButtonColor":"#2F4B73","customizeButtonCaptionColor":"#ffffff","position":"float-bottom-right","backgroundOverlay":false,"textColor":"#ffffff","backgroundColor":"#18335b"}},"googleTagManagerOptions":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KXJD58X","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.thebodycoach.com/"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
